import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../components/Core";

const AccordionWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #ccc;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const AccordionHeader = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  margin-right: 10px; /* Add some margin between the title and icon */
`;

const IconContainer = styled.span`
  font-size: 12px; /* Adjust the font size as needed */
  color: rgb(171, 142, 102);
`;

const AccordionContent = styled.div`
  padding: 0px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const CenteredAccordionItem = styled(AccordionItem)`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${AccordionHeader} {
      width: 100%;
    }

    ${TitleContainer} {
      width: 100%;
      justify-content: center;
    }

    ${IconContainer} {
      margin-left: 0;
      margin-top: 0px; /* Adjust the margin as needed */
    }
  }
`;

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Section py={3}>
      <Container>
        <Row>
          <Col>
            <AccordionWrapper>
              {items.map((item, index) => (
                <CenteredAccordionItem key={index}>
                  <AccordionHeader onClick={() => handleAccordionClick(index)}>
                    <TitleContainer>
                      <Title>{item.title}</Title>
                      <IconContainer>
                        {openIndex === index ? '\u142F' : '\u1433'}
                      </IconContainer>
                    </TitleContainer>
                  </AccordionHeader>
                  <AccordionContent isOpen={openIndex === index}>
                    {typeof item.content === 'string' ? (
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    ) : (
                      item.content
                    )}
                  </AccordionContent>
                </CenteredAccordionItem>
              ))}
            </AccordionWrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Accordion;
