import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Text } from "../components/Core";
import Link from 'gatsby-link';
import Newarrow from '../assets/image/jpeg/enter.svg';
import { Book } from "react-feather";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;




const SectionStyled = styled(Section)`


}

/* Add other styling properties as needed */
`;

const CTA = ({setIsModalOpen}) => (
  <>
<SectionStyled bg="#ffffff" py={4}>
  <Container className="pt-3 pb-3">
    <Row className="justify-content-center align-items-center text-center text-lg-left">
      <Col lg="8" xl="7">
        <div className="py-2">
          <Title color="#1a1a1a">Unsure About What to Book?</Title>
          <Text>
            Why not schedule a free consultation with one of our experts who will assess your needs and provide you with accurate advice for the best decision.
          </Text>
        </div>
      </Col>
      <Col lg="4" xl="5" className="d-flex align-items-center justify-content-center mt-4 mt-md-0">
        <div className="">
          <div onClick={() => setIsModalOpen(true)}>
            <Bookbutton>Book Consultation </Bookbutton>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</SectionStyled>

  </>
);

export default CTA;
